<template>

  <div>
    <b-container style="padding-top: 6rem;" class="mb-4">
      <b-row>
        <b-col></b-col>
        <b-col lg="8" sm="10">
          <b-card bg-variant="secondary" class="mb-2" text-variant="white" title="上传应用">
          </b-card>

          <b-card class="mb-2" text-variant="dark">
            <b-card-text>
              <p><b-icon-lightning variant="info"></b-icon-lightning>
                高效精准的苹果应用预审平台
              </p>
              <p><b-icon-lightning variant="info"></b-icon-lightning>
                基于苹果官方100+案例库，极速全面审核
              </p>
              <p><b-icon-lightning variant="info"></b-icon-lightning>
                微信联系我们：<span style="color: dodgerblue; font-weight: bold">AppleDevOps</span>
                QQ联系我们：<span style="color: dodgerblue; font-weight: bold">253481396</span>
              </p>

            </b-card-text>

            <b-form class="mt-5 ">
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="2"
                :description="progress===100? '': '必填项'">
                <template slot="label">
                  上传IPA <span style="color:red">*</span>
                </template>
                <!--              <b-card bg-variant="light" text-variant="white">-->
                <!--                <b-card-text>-->
                <!--                  <input type="file" accept=".ipa, .IPA, .Ipa">-->
                <!--                  <svg-icon icon-class="ios" style="color: dodgerblue;"/>-->
                <!--                </b-card-text>-->
                <!--                <b-form-file-->
                <!--                  v-model="file"-->
                <!--                  accept=".ipa, .IPA, .Ipa"-->
                <!--                  placeholder=""-->
                <!--                  drop-placeholder=""-->
                <!--                  aria-describedby="ipa-feedback"-->
                <!--                ></b-form-file>-->
                <!--              </b-card>-->
                <b-form-file
                  v-model="file"
                  accept=".ipa, .IPA, .Ipa"
                  placeholder=""
                  drop-placeholder=""
                  aria-describedby="ipa-feedback"
                ></b-form-file>
                <b-form-invalid-feedback id="ipa-feedback">
                  <span>请上传ipa</span>
                </b-form-invalid-feedback>
                <div class="mt-2" v-if="progress>1 && progress<99">
                  <b-progress :value="progress" variant="info" :key="progress"></b-progress>
                </div>
                <div class="mt-2" v-else-if="progress>99 && progress<100">
                  <b-progress :value="progress" variant="success" :key="progress"></b-progress>
                </div>
                <div class="mt-2" v-else-if="progress===100">
                  上传文件成功<b-icon-check variant="success"></b-icon-check>
                </div>

              </b-form-group>
              <b-form-group label-cols-sm="3"
                            label-cols-lg="2"
                            description="输入你的应用名称">
                <template slot="label">
                  应用名称
                </template>
                <b-form-textarea
                  id="input-appName"
                  v-model="form.appName"
                  placeholder=""
                  aria-describedby="appName-feedback"
                  trim
                ></b-form-textarea>
                <b-form-invalid-feedback id="appName-feedback">
                  <span></span>
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group label-cols-sm="3"
                            label-cols-lg="2"
                            label=""
                            description="便于我们联系您, 务必留下">
                <template slot="label">
                  QQ/微信 <span style="color:red">*</span>
                </template>
                <b-form-input
                  id="input-qq"
                  v-model="form.qq"
                  required
                  placeholder=""
                  aria-describedby="qq-feedback"
                  :state="qqState"
                  trim
                ></b-form-input>
                <b-form-invalid-feedback id="qq-feedback">
                  <span>请输入正确的QQ/微信号</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label-cols-sm="3"
                            label-cols-lg="2"
                            label="电话号码"
                            description="便于我们联系您">
                <b-form-input
                  id="input-telephone"
                  v-model="form.telephone"
                  placeholder=""
                ></b-form-input>
              </b-form-group>
            </b-form>
            <b-button style="float: right" type="submit" variant="primary" @click="onSubmit">提交</b-button>

          </b-card>
          </b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
    <InfoModal ref="loginModal"></InfoModal>
  </div>

</template>

<script>

  import UploadComponent from '@/components/upload/UploadComponent'
  import {empty, API_BASE_URL} from "../../../constant";
  import {ipaVersionAdd} from "../../../util/APIUtils";
  import axios from 'axios'
  import { BButton, BForm, BFormFile, BFormGroup,BInputGroup,BInputGroupPrepend,
    BFormInput, BFormTextarea,BFormInvalidFeedback, BCard, BCardText, BProgress,

  } from 'bootstrap-vue'

  import {BIconLightning, BIcon, BIconCheck } from 'bootstrap-vue'


  export default {
    components:{
      UploadComponent,
      BButton, BForm, BFormFile, BFormGroup,BInputGroup,BInputGroupPrepend,
      BFormInput,BFormTextarea,BFormInvalidFeedback,BCard,BCardText, BProgress, BIcon, BIconCheck, BIconLightning
    },
    name: "CustomUpload",
    data(){
      return {
        form:{
          qq: null,
          telephone: null,
          appName: null,
          ipaName: null,
          ipaPath: null,
        },
        reg:{
          qq: /^[1-9]\d{4,9}$/,
          appName: null,
        },
        progress: 0,
        file: null,
        fileRet: null
      }
    },
    watch:{
      'file':  function  (newVale, oldValue) {
        if(Boolean(this.file)){
          const fileReader = new FileReader();
          fileReader.readAsDataURL(this.file);
          //Upload begin from below
          let fd = new FormData();
          fd.append('file',this.file);
          let config = {
            onUploadProgress: progressEvent => {
              let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
              this.progress = complete
              console.log(this.progress);
            }
          };
          axios.post(API_BASE_URL+'/api/front/upload',fd, config).then( res => {
            if( res.data.code === 0){
              this.fileRet = {
                ipaName: res.data.data.name,
                ipaPath: res.data.data.url
              };
            }
          }).catch(error => {
            console.log(error)
          })
        }
      }
    },
    computed:{
      appNameState(){
        if(!this.form.appName ){
           return null;
        }
        return empty(this.form.appName) ? true : false
      },
      qqState(){
        if(!this.form.qq ){
          return null;
        }
        return this.reg.qq.test(this.form.qq) ? true : false
      }
    },
    methods:{
      async onSubmit(){
        if(empty(this.fileRet)){
          if(this.process < 1 ){
            this.$refs.loginModal.showModal('IPA包未上传','确认');
            return;
          }
          if(this.process > 1 && this.process<100){
            this.$refs.loginModal.showModal('IPA正在上传中，请稍后','确认');
            return;
          }
        }else{
          this.form.ipaName = this.fileRet.ipaName;
          this.form.ipaPath = this.fileRet.ipaPath;
        }
        if(!this.qqState){
          this.$refs.loginModal.showModal('QQ号码不对','确认');
          return;
        }
        this.$myLoading.open('正在提交苹果中，大约需要数分钟');
        let res = await ipaVersionAdd(this.form);
        this.$myLoading.hide();
        if(res.code===0){
          this.$refs.loginModal.showModal('提交成功，请联系: 微信AppleDevOps','确认');
        }else{
          this.$refs.loginModal.showModal(res.message,'确认');

        }

      }
    }
  }
</script>

<style scoped>

  /deep/ .text-muted{
    /*color: dodgerblue !important;*/
  }
</style>
