<template>
  <div>
    <b-form-group>
      <b-form-file
        v-model="file"
        accept=".ipa, .IPA, .Ipa"
        placeholder="上传IPA"
        drop-placeholder="拖拽至此处"
      ></b-form-file>
    </b-form-group>
  </div>
</template>

<script>
  import { BFormGroup, BFormFile } from 'bootstrap-vue'
  import axios from 'axios'
  import {API_BASE_URL} from "../../constant";


  export default {
    components:{
      BFormGroup,
      BFormFile
    },
    name: "UploadComponent",
    data(){
      return {
        file: null,
        fileRet: null
      }
    },
    watch:{
      'file':  function  (newVale, oldValue) {
        if(Boolean(this.file)){
          const fileReader = new FileReader()
          fileReader.readAsDataURL(this.file)
          //Upload begin from below
          let fd = new FormData()
          fd.append('file',this.file)
          console.log(this.file)
          axios.post(API_BASE_URL+'/api/front/upload',fd).then( res => {
            console.log(res.data.data)
            if( res.data.code === 0){
              this.fileRet = {
                ipaName: res.data.data.name,
                ipaPath: res.data.data.url
              };
            }
          }).catch(error => {
            console.log(error)
          })
        }
      }
    },
    methods:{
      getFileRet(){
        return this.fileRet;
      }
    }
  }
</script>

<style scoped>

</style>
